// 
// divider.scss
// Theme Component

.divider{
  height: 1px;
  background: $black;
  display: block;
  width: 100%;
  margin: 0 auto;
}
.divider-light{
  height: 1px;
  background: $white;
  display: block;
  width: 100%;
  margin: 0 auto;
}