// Should you need to overwrite any of our SCSS code or need to add any custom code, We highly recommend that you add your code into this file, so whenever theme update is available and you update the theme, You will not lose your hard work :)

/*User CSS*/

// Btn 1
.btn-viewproject-1 {
  top: 185px;
}

@media (min-width: 415px) {
  .btn-viewproject-1 {
    top: 140px;
  }
}

@media (min-width: 598px) {
  .btn-viewproject-1 {
    top: 100px;
  }
}

@media (min-width: 768px) {
  .btn-viewproject-1 {
    top: 240px;
  }
}

@media (min-width: 859px) {
  .btn-viewproject-1 {
    top: 210px;
  }
}

@media (min-width: 992px) {
  .btn-viewproject-1 {
    top: 300px;
  }
}

@media (min-width: 1157px) {
  .btn-viewproject-1 {
    top: 240px;
  }
}

// Btn 2
.btn-viewproject-2 {
  top: 140px;
}

@media (min-width: 442px) {
  .btn-viewproject-2 {
    top: 100px;
  }
}

@media (min-width: 768px) {
  .btn-viewproject-2 {
    top: 200px;
  }
}

@media (min-width: 915px) {
  .btn-viewproject-2 {
    top: 165px;
  }
}

@media (min-width: 992px) {
  .btn-viewproject-2 {
    top: 240px;
  }
}

@media (min-width: 1400px) {
  .btn-viewproject-2 {
    top: 180px;
  }
}

// Btn 3
.btn-viewproject-3 {
  top: 185px;
}

@media (min-width: 376px) {
  .btn-viewproject-3 {
    top: 140px;
  }
}

@media (min-width: 659px) {
  .btn-viewproject-3 {
    top: 100px;
  }
}

@media (min-width: 768px) {
  .btn-viewproject-3 {
    top: 240px;
  }
}

@media (min-width: 770px) {
  .btn-viewproject-3 {
    top: 200px;
  }
}

@media (min-width: 992px) {
  .btn-viewproject-3 {
    top: 300px;
  }
}

@media (min-width: 1034px) {
  .btn-viewproject-3 {
    top: 240px;
  }
}

// Btn 4
.btn-viewproject-4 {
  top: 185px;
}

@media (min-width: 337px) {
  .btn-viewproject-4 {
    top: 140px;
  }
}

@media (min-width: 517px) {
  .btn-viewproject-4 {
    top: 100px;
  }
}

@media (min-width: 768px) {
  .btn-viewproject-4 {
    top: 200px;
  }
}

@media (min-width: 859px) {
  .btn-viewproject-4 {
    top: 210px;
  }
}

@media (min-width: 992px) {
  .btn-viewproject-4 {
    top: 240px;
  }
}

// Btn 5
.btn-viewproject-5 {
  top: 140px;
}

@media (min-width: 357px) {
  .btn-viewproject-5 {
    top: 95px;
  }
}

@media (min-width: 768px) {
  .btn-viewproject-5 {
    top: 160px;
  }
}

@media (min-width: 992px) {
  .btn-viewproject-5 {
    top: 175px;
  }
}

@media (min-width: 1400px) {
  .btn-viewproject-5 {
    top: 180px;
  }
}

// Btn 6
.btn-viewproject-6 {
  top: 140px;
}

@media (min-width: 454px) {
  .btn-viewproject-6 {
    top: 100px;
  }
}

@media (min-width: 768px) {
  .btn-viewproject-6 {
    top: 200px;
  }
}

@media (min-width: 940px) {
  .btn-viewproject-6 {
    top: 165px;
  }
}

@media (min-width: 992px) {
  .btn-viewproject-6 {
    top: 240px;
  }
}

@media (min-width: 1400px) {
  .btn-viewproject-6 {
    top: 180px;
  }
}

//
// Utitlities
//

.text-github {
  color: #333;
  &:hover {
    color: shift-color(#333, 10%);
  }
}

.px-12px {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.lh-1 {
  line-height: 1 !important;
}
