// 
// list-group.scss
// Extended from Bootstrap
// 


// 
// Additional style for theme
// 
.list-group .list-group-item i, .list-group .list-group-item span{
  margin-right: 5px;
}

// list group borderless
.list-group-borderless {
  .list-group-item {
    border: none;
    padding: 0.425rem 0;
  }
  a.list-group-item {
    &:hover {
      background-color: transparent;
      color:  $primary;
    }
  }
}

// list group inline 
.list-group-inline {
  display: block;
  li {
    border: none;
    display: inline-block;
    padding: 0.425rem 0;
  }
  .list-group-item {
    border: none;
    display: inline-block;
    padding: 0.425rem 0;
    margin-right: 30px;
    width: auto;
  }
  &.list-group-icon-primary-bg{
    .list-group-item{
      display: inline-flex;
    }
  }
}
.list-group-item-action {
  &:hover {
    background: transparent;
    color: $primary;
  }
  &:focus {
    background: transparent;
    color: $primary;
  }
}

// list group icon primary soft bg
.list-group-icon-primary-bg{
  .list-group-item{
    display: flex;
  }
  i, span{
    background: shift-color($primary, -50%);
    color: $primary;
    font-weight: $font-weight-bold; 
    height: 22px;
    width: 22px;
    line-height: 23px;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px !important;
    font-size: 11px;
    flex-shrink: 0;
    justify-content: center;
  }
}